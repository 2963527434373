import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of, race } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import {
  CustomQuestion,
  PropertyMatchBean,
  PropertySearcherType
} from '@ui/shared/models';
import { isValueNullOrUndefined } from 'libs/utils';
import { ModalService } from 'libs/components/legacy/modal';
import {
  errorMessageParser,
  LocalStorageService,
  SessionStorageService
} from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';

import * as fromAppReducers from 'tenant-pool/+state/reducers';
import * as fromUserState from 'tenant-pool/+state/user';
import * as fromUserSelectors from 'tenant-pool/+state/user/user.selectors';
import {
  MainPageNavigation,
  notificationConfig as notification,
  storageKeys
} from 'tenant-pool/config';
import { ProposalFacade } from 'tenant-pool/core/services/proposal.facade';
import { ApplicationFacade } from 'tenant-pool/core/services/application.facade';
import { PropertyMatchesFacade } from 'tenant-pool/core/services/property-matches.facade';
import { ApplyConfirmationComponent } from 'tenant-pool/components/apply-confirmation/apply-confirmation.component';
import { TenantAcceptanceModalComponent } from 'tenant-pool/components/tenant-acceptance-modal/tenant-acceptance-modal.component';
import { PropertyInterestComponent } from 'libs/components/legacy/property-interest/property-interest.component';
import { TenantExperienceModalComponent } from 'tenant-pool/components/tenant-experience-modal/tenant-experience-modal.component';
import { ActivatedRoute } from '@angular/router';
import { ConfirmApplyModalComponent } from 'tenant-pool/components/confirm-apply-modal/confirm-apply-modal.component';
import { defaultAdvertising } from '../../config/hideTypes';
import * as fromAppUserState from '../user';
import * as fromCustomQuestionActions from '../custom-questions/custom-questions.actions';
import * as fromSelfDisclosureActions from '../self-disclosure/self-disclosure.actions';
import * as fromSearchProfileActions from '../search-profiles/search-profiles.actions';
import { RemoveAppointmentsLinkedToApplication } from '../appointments';
import * as fromActions from './property-matches.actions';
import * as fromSelectors from './property-matches.selector';
import { OpenCompleteModal } from './property-matches.actions';

@Injectable()
export class PropertyMatchesEffects {
  private actions$ = inject(Actions);
  private facade = inject(PropertyMatchesFacade);
  private applicationFacade = inject(ApplicationFacade);
  private proposalFacade = inject(ProposalFacade);
  private store = inject<Store<fromAppReducers.AppState>>(Store);
  private sessionStorage = inject(SessionStorageService);
  private localStorage = inject(LocalStorageService);
  private modalService = inject(ModalService);
  private localStorageService = inject(LocalStorageService);
  private route = inject(ActivatedRoute);

  loadPropertyMatchesBeans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPropertyMatchesBeans),
      switchMap(({ data, loadMore }) =>
        this.facade.loadPropertyMatchesBeans(data).pipe(
          map(result =>
            fromActions.LoadPropertyMatchesBeansSuccess({ result, loadMore })
          ),
          catchError(error => [
            fromActions.LoadPropertyMatchesBeansFail({ error, loadMore })
          ])
        )
      )
    )
  );

  loadPropertyMatchesRented$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPropertyMatchesRented),
      switchMap(({ data, loadMore }) =>
        this.facade.loadPropertyMatchesRented(data).pipe(
          map(result =>
            fromActions.LoadPropertyMatchesRentedSuccess({ result, loadMore })
          ),
          catchError(error => [
            fromActions.LoadPropertyMatchesRentedFail({ error, loadMore })
          ])
        )
      )
    )
  );

  loadPropertyMatchBean$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPropertyMatchBean),
      switchMap(({ id }) =>
        this.facade.loadPropertyMatchBean(id).pipe(
          map(result => fromActions.LoadPropertyMatchBeanSuccess({ result })),
          catchError(error => [
            fromActions.LoadPropertyMatchBeanFail({ error }),
            new fromBaseState.Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.PROPERTY_NOT_FOUND
              ]
            })
          ])
        )
      )
    )
  );

  loadPropertyMatchBeanQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPropertyMatchBeanQuestions),
      switchMap(({ id, status }) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
        this.facade.loadPropertyMatchBeanQuestions(id, status).pipe(
          map(res =>
            fromActions.LoadPropertyMatchBeanQuestionsSuccess({
              id,
              status,
              questionContainer: res.data.propertyApplyProcessPSBeanQuestions
            })
          ),
          catchError(error => [
            fromActions.LoadPropertyMatchBeanQuestionsFail({ error })
          ])
        )
      )
    )
  );

  loadRentedFlatsApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadRentedFlatsApplications),
      switchMap(() => this.applicationFacade.loadRentedFlatsApplications()),
      map(applications =>
        fromActions.LoadRentedFlatsApplicationsSuccess({ applications })
      ),
      catchError(error => [fromActions.LoadRentedFlatsApplicationsFail(error)])
    )
  );

  loadRentedFlatsApplicationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadRentedFlatsApplicationsSuccess),
      withLatestFrom(this.store.select(fromUserSelectors.getUserData)),
      switchMap(([{ applications }, userData]) => {
        if (!applications) return [];
        const ids =
          this.localStorage.getItem<string[]>(
            storageKeys.rentedPropertiesIds
          ) || [];
        const foundApplication = applications.find(
          application => !ids.includes(application.property.id)
        );

        const property = foundApplication?.property;

        if (!property) return [];

        this.localStorage.setItem(storageKeys.rentedPropertiesIds, [
          ...ids,
          property.id
        ]);

        const modal = this.modalService.open<TenantAcceptanceModalComponent>(
          TenantAcceptanceModalComponent,
          {
            data: {
              property
            },
            windowClass: 'new-home__modal-container'
          }
        );

        return race(
          modal.onClose().pipe(
            mergeMap(({ activateSearchProfile, closeOpenApplication }) => {
              const actions = [] as any;
              if (activateSearchProfile) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                actions.push(
                  new fromSearchProfileActions.ActivateAllSearchProfiles(
                    property.type
                  )
                );
              }
              if (!closeOpenApplication) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                actions.push(fromActions.RevokeAllOpenApplications());
              }
              if (!this.route.snapshot.queryParams.fromMail) {
                this.modalService.open<TenantExperienceModalComponent>(
                  TenantExperienceModalComponent,
                  {
                    data: {
                      foundApplication,
                      rentDepositInfo: foundApplication.rentDepositInfo,
                      itpSettings: userData.customerBranding?.itpSettings
                    },
                    windowClass: 'new-home__modal-container'
                  }
                );
              }
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return actions;
            })
          ),
          modal.onDismiss().pipe(
            mergeMap(() => [
              new fromSearchProfileActions.ActivateAllSearchProfiles(
                property.type
              ),
              fromActions.LoadPropertyMatchesRented({
                data: { page: 0, sort: 'id,asc', size: 100 }
              }),
              new fromBaseState.Go({
                path: [MainPageNavigation.NEW_HOME]
              })
            ])
          )
        );
      })
    )
  );

  removeApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RemoveApplication),
      switchMap(({ reasonData, rented }) =>
        this.applicationFacade.removeApplication(reasonData, rented).pipe(
          withLatestFrom(
            this.store.select(fromSelectors.getPropertyMatchesRented)
          ),
          mergeMap(([_, rentedFlats]) => {
            let path = [
              MainPageNavigation.PROPERTIES,
              MainPageNavigation.APPLICATIONS
            ];
            if (rented && rentedFlats?.length - 1 > 0) {
              path = [MainPageNavigation.NEW_HOME];
            }
            return [
              new fromBaseState.DeselectConversation(),
              new fromBaseState.StopFindUnreadMessagesPolling(),
              new fromBaseState.LoadConversations(),
              new RemoveAppointmentsLinkedToApplication(
                reasonData.propertySearcherId
              ),
              fromActions.RemoveApplicationSuccess({
                id: reasonData.propertySearcherId
              }),
              new fromBaseState.Go({
                path
              })
            ];
          }),
          catchError(() => [
            fromActions.RemoveApplicationFail({
              error: new Error(notification.property.remove.error)
            })
          ])
        )
      )
    )
  );

  declareIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DeclareIntent),
      switchMap(({ declareIntentData }) =>
        this.applicationFacade.declareIntent(declareIntentData).pipe(
          mergeMap(() => [
            fromActions.DeclareIntentSuccess({
              id: declareIntentData.propertySearcherId,
              intent: declareIntentData.intent
            }),
            fromActions.UncacheIntent(),
            new fromBaseState.ShowInfo(
              notification.application.declareIntent.success
            )
          ]),
          catchError(() => [
            fromActions.DeclareIntentFail({
              error: new Error(notification.property.remove.error)
            }),
            new fromBaseState.ShowError(
              notification.application.declareIntent.error
            )
          ])
        )
      )
    )
  );

  cacheIntent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.CacheIntent),
        map(action => action.applicationId),
        tap(applicationId =>
          this.sessionStorage.setItem(
            storageKeys.confirmIntentForApplication,
            applicationId
          )
        )
      ),
    { dispatch: false }
  );

  unCacheIntent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.UncacheIntent),
        tap(() =>
          this.sessionStorage.removeItem(
            storageKeys.confirmIntentForApplication
          )
        )
      ),
    { dispatch: false }
  );

  denyIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DenyIntent),
      switchMap(({ declareIntentData, token }) =>
        this.applicationFacade.denyIntent(declareIntentData, token).pipe(
          mergeMap(() => [
            fromActions.DenyIntentSuccess(),
            new fromBaseState.Go({
              path: ['/properties']
            }),
            new fromBaseState.ShowInfo(
              notification.application.denyIntent.success
            )
          ]),
          catchError(error => [
            fromActions.DenyIntentFail(error),
            new fromBaseState.ShowError(
              notification.application.denyIntent.error
            )
          ])
        )
      )
    )
  );

  checkIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CheckIdentity),
      switchMap(({ identityToken }) =>
        this.applicationFacade.checkIdentity(identityToken).pipe(
          map(() => fromActions.CheckIdentitySuccess()),
          catchError(e => [
            new fromBaseState.ShowError(
              notification.application.identityCheck.error
            ),
            fromActions.CheckIdentityFail(e)
          ])
        )
      )
    )
  );

  CheckApplicationExist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CheckApplicationExist),
      switchMap(({ propertyId, token }) =>
        this.applicationFacade.checkApplicationExist(propertyId).pipe(
          map(id =>
            fromActions.CheckApplicationExistSuccess({ id, propertyId, token })
          ),
          catchError(e => [fromActions.CheckApplicationExistFail(e)])
        )
      )
    )
  );

  checkApplicationExistSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CheckApplicationExistSuccess),
      mergeMap(({ id, propertyId, token }) =>
        id
          ? [fromActions.LoadPropertyMatchBean({ id })]
          : [fromActions.CreateApplication({ id: propertyId, token })]
      )
    )
  );

  createApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CreateApplication),
      withLatestFrom(this.store.select(fromUserSelectors.getUserId)),
      switchMap(([{ id: propertyId, token }, userId]) =>
        this.applicationFacade
          .createApplication(userId, propertyId, token)
          .pipe(
            mergeMap(application => {
              return [
                fromActions.LoadPropertyMatchBean({ id: application.id }),
                fromActions.CreateApplicationSuccess({ application }),
                new fromUserState.LoadUserData()
              ];
            }),
            catchError(error => {
              const parsedError = errorMessageParser(
                error,
                notification.application.create.error
              );

              this.sessionStorage.removeItem(
                storageKeys.redirectedToProfileAfterApply
              );
              this.localStorage.removeItem(storageKeys.portalRegistration);

              return [fromActions.CreateApplicationFail(parsedError)];
            })
          )
      )
    )
  );

  createApplicationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.CreateApplicationSuccess),
        map(({ application }) => {
          const portalRegistration = this.localStorage.getItem<boolean>(
            storageKeys.portalRegistration
          );
          this.localStorage.removeItem(storageKeys.portalRegistration);
          this.sessionStorage.removeItem(
            storageKeys.redirectedToProfileAfterApply
          );

          // Don't make an additional redirect, because for portal registrations it's already handled
          // in the action Register in the user.effects.ts
          if (portalRegistration) return;

          this.store.dispatch(
            new fromBaseState.Go({
              path: [MainPageNavigation.EXPOSE, application.id],
              query: {
                origin: `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.APPLICATIONS}`
              }
            })
          );
        })
      ),
    { dispatch: false }
  );

  applyAnonymously$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ApplyAnonymously),
      switchMap(({ anonymousApplicationPayload }) =>
        this.applicationFacade
          .applyAnonymously(anonymousApplicationPayload)
          .pipe(
            mergeMap(() => [
              fromActions.ApplyAnonymouslySuccess(),
              new fromBaseState.ShowInfo(notification.application.apply.success)
            ]),
            catchError(error => {
              const errorMessage = errorMessageParser(
                error,
                'notification.application.apply.error'
              );
              return [
                fromActions.ApplyAnonymouslyFail(error),
                new fromBaseState.ShowError(errorMessage)
              ];
            })
          )
      )
    )
  );

  findAvailableForConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.FindAvailableForNewConversation),
      map(action => action.userId),
      switchMap(userId =>
        this.applicationFacade.findAvailableForNewConversation(userId).pipe(
          map(result => result.data.findAvailableForNewConversation),
          map(applications =>
            fromActions.FindAvailableForNewConversationSuccess({ applications })
          ),
          catchError(error => [
            fromActions.FindAvailableForNewConversationError({ error })
          ])
        )
      )
    )
  );

  applicationActionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RemoveApplicationFail),
      map(({ error }) => new fromBaseState.ShowError(error.message))
    )
  );

  removeProposal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DenyProposal),
      switchMap(({ reasonData }) =>
        this.proposalFacade.denyProposal(reasonData).pipe(
          map(() =>
            fromActions.DenyProposalSuccess({
              id: reasonData.propertySearcherId
            })
          ),
          catchError(() => [
            fromActions.DenyProposalFail({
              error: new Error(notification.property.remove.error)
            })
          ])
        )
      )
    )
  );

  RevokeAllOpenApplications = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RevokeAllOpenApplications),
      switchMap(() =>
        this.applicationFacade.revokeAllOpenApplications().pipe(
          map(() => fromActions.RevokeAllOpenApplicationsSuccess()),
          catchError(() => [
            fromActions.RevokeAllOpenApplicationsFail({
              error: new Error(
                notification.propertyApplyProcessPSBeans.close.error
              )
            })
          ])
        )
      )
    )
  );

  acceptProposal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.AcceptProposal),
      switchMap(({ id: proposalId, name }) =>
        this.proposalFacade.acceptProposal(proposalId).pipe(
          mergeMap(response => {
            this.modalService.open<ApplyConfirmationComponent>(
              ApplyConfirmationComponent,
              { data: { name } }
            );

            let successAction: any = [
              fromActions.AcceptProposalSuccess({ id: proposalId })
            ];

            if (response?.data?.acceptProposal?.id) {
              successAction = [
                ...successAction,
                new fromBaseState.Go({
                  path: [
                    MainPageNavigation.EXPOSE,
                    response.data.acceptProposal.id
                  ],
                  query: {
                    origin: `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.APPLICATIONS}`
                  }
                })
              ];
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return [...successAction];
          }),
          catchError(() => [
            fromActions.AcceptProposalFail({
              error: new Error(notification.property.accept.error)
            })
          ])
        )
      )
    )
  );

  proposalActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.DenyProposalSuccess,
        fromActions.AcceptProposalSuccess
      ),
      map(
        () =>
          new fromBaseState.Go({
            path: [
              MainPageNavigation.PROPERTIES,
              MainPageNavigation.APPLICATIONS
            ]
          })
      )
    )
  );

  proposalActionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DenyProposalFail, fromActions.AcceptProposalFail),
      map(action => new fromBaseState.ShowError(action.error.message))
    )
  );

  openCompleteModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.OpenCompleteModal),
        switchMap(data =>
          // When you register as a PS via a registration link, then it can happen that the userData isn't loaded yet,
          // after you get redirected into the app
          this.store.select(fromAppUserState.getUserDataLoaded).pipe(
            filter(loaded => loaded),
            take(1),
            // Return the data from the action again
            map(() => data)
          )
        ),
        withLatestFrom(
          this.store.select(fromAppUserState.getUserData),
          this.store
            .select(fromAppUserState.getProfileCompleteness)
            .pipe(map(value => value === 100))
        ),
        take(1),
        switchMap(
          ([
            { showGenericModal, propertyMatch },
            userData,
            isProfileComplete
          ]) => {
            if (isProfileComplete) return of(propertyMatch);

            const modal = this.modalService.open<ConfirmApplyModalComponent>(
              ConfirmApplyModalComponent,
              {
                data: {
                  profileComplete: isProfileComplete,
                  profileCompleteness$: this.store.select(
                    fromAppUserState.getProfileCompleteness
                  ),
                  showSchufaLink:
                    userData.customerBranding?.itpSettings?.schufaCard ??
                    defaultAdvertising.schufaCard,
                  hideApplyNowButton: showGenericModal,
                  showGenericText: showGenericModal
                }
              }
            );

            return modal.onClose().pipe(map(() => propertyMatch));
          }
        ),
        tap(propertyMatch => {
          if (propertyMatch) {
            this.store.dispatch(
              fromActions.AcceptProposal({
                id: propertyMatch?.id,
                name: propertyMatch?.property.data.name
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  openIntentModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.OpenIntentModal),
        switchMap(({ application, intent, fromEmail }) =>
          this.store.select(fromBaseState.getConstants).pipe(
            filter(constants => !!constants),
            map(constants => constants.refusalReasonTypes),
            take(1),
            withLatestFrom(
              this.store.select(fromUserSelectors.getProfileCompleteness)
            ),
            tap(([reasons, profileComplete]) => {
              const modalPromise =
                this.modalService.promisifyOpen<PropertyInterestComponent>(
                  PropertyInterestComponent,
                  {
                    data: {
                      propertyName: application?.property?.data?.name,
                      propertyType: application?.property?.type,
                      intent,
                      reasons,
                      fromEmail,
                      profileComplete,
                      askForViewingAppointmentAttendance:
                        application.askForViewingAppointmentAttendance,
                      editProfile: () => {
                        this.store.dispatch(
                          new fromBaseState.Go({
                            path: [MainPageNavigation.PROFILE, 'edit', 'step']
                          })
                        );
                      }
                    }
                  }
                );
              void modalPromise.then(modal => {
                modal.onClose().subscribe(result => {
                  const hasIntent = result.intent;
                  if (!!application.property.selfDisclosureId && hasIntent) {
                    this.store.dispatch(
                      new fromSelfDisclosureActions.LoadSelfDisclosureData(
                        application
                      )
                    );
                    this.localStorageService.setItem(
                      storageKeys.selfDisclosureIsDeclareIntent,
                      true
                    );
                    this.store.dispatch(
                      new fromSelfDisclosureActions.OpenSelfDisclosuresModal()
                    );
                  } else {
                    // DeclareIntent either if self disclosure is not required or if NO_INTENT

                    const declarePayload = {
                      ...result,
                      propertySearcherId: application.id
                    };
                    this.store.dispatch(
                      fromActions.DeclareIntent({
                        declareIntentData: declarePayload
                      })
                    );
                  }
                });

                modal.onDismiss().subscribe(result => {
                  if (result) {
                    this.store.dispatch(
                      fromActions.CacheIntent({ applicationId: application.id })
                    );
                  } else {
                    this.store.dispatch(fromActions.UncacheIntent());
                  }
                });
              });
            })
          )
        )
      ),
    { dispatch: false }
  );

  openPostLoginModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.OpenPostLoginModal),
        map(() => {
          const confirmIntentForApplication = this.sessionStorage.getItem(
            storageKeys.confirmIntentForApplication
          );
          const applicationId = this.localStorageService.getItem(
            storageKeys.customQuestionApplicationId
          );
          this.localStorageService.removeItem(
            storageKeys.customQuestionApplicationId
          );

          if (confirmIntentForApplication) {
            this.store.dispatch(
              fromActions.LoadPropertyMatchBean({
                id: confirmIntentForApplication
              })
            );
            this.store.dispatch(fromActions.UncacheIntent());
          }

          return {
            applicationId,
            confirmIntentForApplication
          };
        }),
        switchMap(({ applicationId, confirmIntentForApplication }) =>
          this.store.select(fromSelectors.getPropertyMatchDataActionState).pipe(
            filter(state => !state.pending),
            take(1),
            switchMap(() =>
              this.store.select(fromSelectors.getPropertyMatchesData).pipe(
                filter(
                  (propertiesData: PropertyMatchBean[]) =>
                    propertiesData?.length > 0
                ),
                take(1),
                map((propertiesData: PropertyMatchBean[]) => ({
                  propertiesData,
                  applicationId,
                  intentApplication: propertiesData.find(
                    p =>
                      p.id.toString() ===
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      confirmIntentForApplication?.toString()
                  ),
                  customQuestionProperty:
                    propertiesData.find(
                      this.findByIdWithCustomQuestions(applicationId)
                    ) || propertiesData.find(this.findByOpenCustomQuestions())
                })),
                tap(
                  ({
                    applicationId,
                    customQuestionProperty,
                    intentApplication
                  }) => {
                    if (intentApplication) {
                      this.store.dispatch(
                        fromActions.OpenIntentModal({
                          application: intentApplication,
                          intent: 'INTENT',
                          fromEmail: false
                        })
                      );
                    } else if (
                      customQuestionProperty &&
                      customQuestionProperty.type ===
                        PropertySearcherType.APPLICATION
                    ) {
                      this.localStorageService.removeItem(
                        storageKeys.customQuestionApplicationId
                      );
                      this.store.dispatch(
                        new fromCustomQuestionActions.OpenCustomQuestionsModal(
                          customQuestionProperty
                        )
                      );
                    } else if (applicationId && !customQuestionProperty) {
                      this.store.dispatch(
                        OpenCompleteModal({ showGenericModal: true })
                      );
                    }
                  }
                )
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  private findByIdWithCustomQuestions(applicationId: string) {
    return (propertyMatch: PropertyMatchBean) => {
      return (
        propertyMatch.id.toString() === applicationId &&
        propertyMatch.hasQuestions &&
        (!!propertyMatch.questionContainer?.customQuestions?.length ||
          !!propertyMatch.questionContainer?.questions?.length)
      );
    };
  }

  private findByOpenCustomQuestions() {
    return (propertyMatch: PropertyMatchBean) => {
      return (
        propertyMatch.hasQuestions &&
        (propertyMatch.questionContainer?.customQuestions?.some(
          (c: CustomQuestion) => isValueNullOrUndefined(c.answer)
        ) ||
          propertyMatch.questionContainer?.questions.some(container => {
            const mainQuestionResponse = container.responses?.find(
              response =>
                response.questionId === container.rootQuestion.mainQuestionId
            );
            return (
              !mainQuestionResponse ||
              (!mainQuestionResponse?.data.response &&
                !mainQuestionResponse?.data.answerIds)
            );
          }))
      );
    };
  }
}
