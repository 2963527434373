<app-card [class]="baseClass">
  <div
    class="d-flex align-items-center align-items-md-center align-items-lg-end"
  >
    <div [class]="baseClass + '__image-wrapper'">
      <app-image
        [defaultSrc]="imageUrlWithFallback"
        [alt]="title"
        [objectFit]="'cover'"
        [borderRadius]="'big'"
        [borderStyle]="!imageUrlHasBeenSet() ? 'neutral' : null"
      ></app-image>
    </div>

    <div
      class="d-flex flex-wrap flex-column justify-content-around"
      [class]="baseClass + '__info'"
    >
      <div [class]="baseClass + '__badges'">
        <ng-content select="[badges]"></ng-content>
      </div>
      @if (title) {
        <div [class]="baseClass + '__title'">{{ title }}</div>
      }
      @if (subTitle) {
        <div [class]="baseClass + '__sub-title'">{{ subTitle }}</div>
      }
    </div>

    <div
      class="d-flex align-items-end"
      [class]="baseClass + '__properties-count'"
      [ngbTooltip]="propertiesCountTooltipText | translate"
    >
      <div class="d-flex align-items-center">
        @if (propertiesCount !== null) {
          <span class="icon icon--house-type me-2"></span>
        }
        <span [class]="baseClass + '__properties-count-text'">
          {{ propertiesCount }}
          @if (propertiesCountTotal) {
            / {{ propertiesCountTotal }}
          }
          @if (propertiesCountAdded) {
            <span [class]="baseClass + '__properties-count-added'">
              (+{{ propertiesCountAdded }})</span
            >
          }
        </span>
      </div>
    </div>

    <div [class]="baseClass + '__context-menu-wrapper'">
      <app-context-menu [ghostButton]="true">
        <div menu-content>
          <ng-content select="[menu-content]"></ng-content>
        </div>
      </app-context-menu>
    </div>
  </div>
</app-card>
